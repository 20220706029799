import { Box, Button, IconButton, TableBody, Typography } from '@mui/material'
import { AnimatePresence } from 'framer-motion'

import BlockIcon from '@mui/icons-material/Block'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import EditIcon from '@mui/icons-material/Edit'
import Link from '~/config/Link'
import { formatDate, formatDateTime } from '~/core/utils/formatDate'
import { camelToSentence } from '~/core/utils/formatString'
import sharedProps from '../../sharedProps'
import Chip from '../Chip'
import LoadingIndicators from '../LoadingIndicators'

import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  People,
  SwapVert,
} from '@mui/icons-material'
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import React from 'react'
import addressToArray from '~/routes/ViewProposal/addressToArray'
import { usd } from '../../../core/utils/formatNumber'
import AppStyles from '../../Layouts/AppStyles'
import Pagination from '../Pagination'
import AutoSearchResetButton from './AutoSearchResetButton'
import AutoTableSearchColumns from './AutoTableSearchColumns'
import AutoTableSearchForm from './AutoTableSearchForm'

export {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'

// export const Table = styled('div')({
//   width: '100%',
//   overflow: 'hidden',
//   minHeight: 120,
//   // background: '#fff',
// })

// export const TableRow = styled('div')(({ theme }) => ({
//   borderBottom: '1px solid rgba(224, 224, 224, 1)',
//   [theme.breakpoints.up('sm')]: {
//     display: 'flex',
//     '&:last-of-type': { border: 'none' },
//   },
//   [theme.breakpoints.only('xs')]: {
//     // flexDirection: 'column',
//     // boxShadow: '0px 0px 14px rgba(53, 64, 82, 0.05)',
//     // marginBottom: 24,
//     // '&:not(:last-child)': {
//     // marginBottom: '32px',
//     // },
//   },
// }))

// export const MotionRow = ({ ...rest }) => (
//   <TableRow {...rest} {...sharedProps.motion.collapse} />
// )

// export const TableHead = styled(TableRow)(({ theme }) => ({
//   [theme.breakpoints.only('xs')]: {
//     '&&': { display: 'none' },
//   },
// }))

// export const TableCell = styled('div')(({ theme, heading, alignRight }) => ({
//   overflow: 'hidden',
//   textOverflow: 'ellipsis',
//   [theme.breakpoints.up('sm')]: {
//     padding: '16px 8px',
//     '&:last-of-type': { paddingRight: 4 },
//     '&:first-of-type': { paddingLeft: 4 },
//     textAlign: alignRight ? 'right' : 'left',
//   },

//   [theme.breakpoints.only('xs')]: {
//     // '&:not(:last-child)': {
//     //   borderBottom: '1px solid rgba(224, 224, 224, 1)',
//     // },
//     '&:last-of-type': {
//       marginBottom: 24,
//     },
//     '&:first-of-type': {
//       marginTop: 24,
//     },
//     padding: '4px 0px',
//     width: '100%',
//     display: 'flex',
//     ...(heading
//       ? {
//           ...theme.typography.h5,
//           display: 'flex',
//           alignItems: 'center',
//           // marginTop: '32px',
//           '& + div': {
//             borderBottom: 'none',
//           },
//           order: 1,
//           // marginTop: '24px',
//         }
//       : {
//           '&:before': {
//             content: 'attr(title)',
//             fontWeight: 'bold',
//             width: '30%',
//             display: 'inline-block',
//             flexShrink: 0,
//           },
//         }),
//   },
// }))

export const ChipCell = ({ children, ...rest }) => (
  <TableCell {...rest}>
    <Chip label={children} />
  </TableCell>
)

export const DateCell = ({ children, ...rest }) => (
  <TableCell {...rest}>{formatDate(children)}</TableCell>
)

export const DateTimeCell = ({ children, ...rest }) => (
  <TableCell {...rest}>{formatDateTime(children)}</TableCell>
)

export const ContentPreviewCell = ({ children, ...props }) => (
  <TableCell {...props}>
    <Box
      sx={{
        width: '100%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        display: 'block',
        maxWidth: '40vw',
        fontStyle: 'italic',
      }}
    >
      {children}
    </Box>
  </TableCell>
)

const formatColumns = columns =>
  columns
    .filter(v => !!v)
    .map(col =>
      typeof col === 'string'
        ? { headerName: camelToSentence(col?.split('.').at(-1)), field: col }
        : { headerName: camelToSentence(col.field?.split('.').at(-1)), ...col }
    )

export const EditCell = ({
  data,
  sx,
  onClick,
  to = onClick ? undefined : 'edit',
  ...rest
}) => (
  <TableCell {...rest} sx={{ ...sx, px: 0 }}>
    <Link to={to} state={{ data }}>
      <Button
        {...sharedProps.button.table}
        startIcon={<EditIcon />}
        onClick={() => onClick?.(data)}
      >
        Edit
      </Button>
    </Link>
  </TableCell>
)

export const UsersCell = ({ data, ...rest }) => (
  <TableCell sx={{ px: '0 !important' }}>
    <Link to="../users" state={data}>
      <Button {...sharedProps.button.table} startIcon={<People />}>
        Users
      </Button>
    </Link>
  </TableCell>
)

export const CurrencyCell = ({ children, ...rest }) => (
  <TableCell>{usd.format(children)}</TableCell>
)

export const AddressCell = ({ data, ...rest }) => (
  <TableCell>{addressToArray(data?.address).join(', ')}</TableCell>
)

export const ButtonCell = ({
  data,
  sx,
  to,
  onClick,
  label = 'Edit',
  color,
  startIcon,
  disabled,
  // children = 'Edit',
  ...rest
}) => (
  <TableCell {...rest} sx={{ ...sx, px: 0 }}>
    <Link to={to} state={{ data }}>
      <Button
        {...sharedProps.button.table}
        {...{ startIcon, color, onClick, disabled }}
      >
        {label}
      </Button>
    </Link>
  </TableCell>
)

export const DeleteCell = ({
  data,
  sx,
  onClick,
  label = 'Delete',
  startIcon = <DeleteOutlineIcon />,
  ...rest
}) => (
  <TableCell {...rest} sx={{ ...sx, px: 0 }}>
    <Button
      onClick={() => onClick?.(data)}
      {...sharedProps.button.table}
      color="error"
      startIcon={startIcon}
    >
      {label}
    </Button>
  </TableCell>
)

export const DisableCell = props => (
  <DeleteCell label="Disable" startIcon={<BlockIcon />} {...props} />
)

export const SortButton = ({ order, ...rest }) => (
  <IconButton sx={{ width: 24, height: 24 }} size="small" {...rest}>
    {order === 'asc' ? (
      <KeyboardArrowUp />
    ) : order === 'desc' ? (
      <KeyboardArrowDown />
    ) : (
      <SwapVert sx={{ opacity: 0.3 }} />
    )}
  </IconButton>
)

export const SortTableCell = ({ sort, field, children, ...rest }) => (
  <TableCell {...rest}>
    {sort?.onSortClick && (
      <SortButton
        order={sort?.field === field && sort?.order}
        onClick={() => sort.onSortClick?.(field)}
      />
    )}
    {children}
  </TableCell>
)

const defaultSortColumns = [
  // user
  'firstName',
  'lastName',
  'email',
  'inviteSentAt',
  'inviteAcceptedAt',
  'role',
  // company
  'company.companyName',
  'companyName',
  'companyType',
  'status',
  'markupRate',
  //proposal
  'createdAt',
  'customerEmail',
  'sentDate',
  'name',
  'description',
  'unitPrice',
  'updatedAt',
  'lastLoginAt',
  'sku',
]

const getColumnWidth = ({ field, ...rest }) => {
  if (!field) return
  if (field.startsWith('edit'))
    return { width: 72, '& > *': { visibility: 'hidden' } }
  if (field === 'delete')
    return { width: 80, '& > *': { visibility: 'hidden' } }
  if (field === 'disable')
    return { width: 80, '& > *': { visibility: 'hidden' } }
  if (field === 'status') return { width: 80 }
  if (field === 'discount') return { width: 80 }
  if (field === 'viewMaterials') return { width: 100 }
  if (field.toLowerCase().includes('email')) return { width: 180 }
  if (field.includes('At')) return { width: 100 }
  if (field.toLowerCase().includes('date')) return { width: 100 }
  // return { width: 140 }
}

function AutoTable({
  columns: propsColumns,
  uniqueField,
  noResultsText = 'No results to show',
  // titleField,
  data,
  isLoading,
  isSubmitting,
  isValidating,
  isLagging,
  sort,
  pagination,
  onRowClick,
  setSearch,
  fixedPagination,
  sx: { tableLayout, ...sx } = {},
  appStyles,
  // enableTransparency,
  ...rest
}) {
  const columns = formatColumns(propsColumns || Object.keys(data?.[0] || {}))

  // const [sorting, setSorting] = React.useState({})

  return (
    <>
      <AutoTableSearchForm setSearch={setSearch}>
        {appStyles && <AppStyles />}
        <TableContainer
          component="div"
          sx={{
            ...sx,
            overflowY: 'visible',
            // maxHeight: '100%',
            height: '100%', // APPSTYLE
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            // mt: '4px',

            // height: 'calc(100% - 32px)',
            // width: '100%',
            // width: 'calc(100% +32px)',
            // mx: 2,
            // mt: 2,
            // pb: 3,
            // mr: 2,
            // borderRight: '16px solid #fff',
            // mt: 2,
            // pb: 2,
            // pb: 4,
          }}
        >
          <LoadingIndicators
            {...{ isValidating, isLoading, isLagging, data, isSubmitting }}
          />
          <Table
            // stickyHeader={appStyles}
            stickyHeader
            size="small"
            sx={{
              '& td, & th': { px: 0.5, py: 1 },
              '& td:first-child, & th:first-child': { pl: 0 },
              '& td:last-child, & th:last-child': { pr: 0 },
              '& th': {
                bgcolor: 'background.paper',
                pt: 2,
                background: 'rgba(255,255,255,0.8)',
                backdropFilter: 'blur(2px)',
              },
              px: 2,
              // flexGrow: 1,
              // tableLayout,
              // tableLayout: 'fixed',
            }}
          >
            <TableHead>
              <TableRow>
                {columns.map(
                  ({
                    field,
                    headerName,
                    headerSearch,
                    breakpoints,
                    sx,
                    sortable,
                  }) => (
                    <TableCell
                      variant="head"
                      sx={{
                        fontWeight: 'bold',
                        py: 2,
                        display: breakpoints?.reduce(
                          (display, bp) => ({
                            ...display,
                            [bp]: 'table-cell',
                          }),
                          { xs: 'none' }
                        ),
                        ...getColumnWidth({ field }),
                        // ...defaultWidths,
                        // minWidth: 100,
                        // maxWidth: 100,
                        ...sx,
                      }}
                      key={field}
                      // {...useFortifyWidth({
                      //   maxWidth: 400,
                      //   enabled: !isLoading && data,
                      // })}
                    >
                      <Box sx={{ display: 'flex', whiteSpace: 'nowrap' }}>
                        {headerName}
                        {sort?.onSortClick &&
                          (sortable ||
                            (sortable !== false &&
                              defaultSortColumns.includes(field))) && (
                            <SortButton
                              order={sort?.field === field && sort?.order}
                              onClick={() => sort.onSortClick?.(field)}
                            />
                          )}
                        {typeof setSearch !==
                        'function' ? null : headerSearch ? (
                          React.cloneElement(headerSearch, {
                            name: field,
                          })
                        ) : (
                          <AutoTableSearchColumns name={field} />
                        )}
                      </Box>
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((row, i) => (
                <TableRow
                  key={row[uniqueField] || i}
                  onClick={() => onRowClick?.(row)}
                  // selected={row.modelNumber === selectedProduct?.modelNumber}
                  hover={!!onRowClick}
                  selected={row.selected}
                  sx={{ cursor: !!onRowClick ? 'pointer' : 'default' }}
                  // sx={{ background: 'blue' }}
                >
                  {columns.map(
                    ({
                      field,
                      Component = TableCell,
                      breakpoints,
                      sx,
                      ...props
                    }) => (
                      <Component
                        data={row}
                        key={field}
                        align="left" // You can adjust the alignment as needed
                        sx={{
                          // ...defaultWidths,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: breakpoints?.reduce(
                            (display, bp) => ({
                              ...display,
                              [bp]: 'table-cell',
                            }),
                            { xs: 'none' }
                          ),
                          // ...sx,
                        }}
                        {...props}
                      >
                        {field
                          ?.split('.')
                          .reduce((p, c) => (p && p[c]) || null, row)}
                      </Component>
                    )
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box sx={{ flexGrow: 1 }}>
            {!data?.length && (
              <Typography sx={{ py: 12, textAlign: 'center' }}>
                {/* {data?.length === 0 ? noResultsText : 'Loading...'} */}
                {isLoading ? 'Loading...' : noResultsText}
              </Typography>
            )}
          </Box>
          {pagination && (
            <Pagination
              sx={{
                // height: 50,
                width: '100%',
                position: 'sticky',
                left: 0,
                // p: 0,
                pt: 1,
                pr: 2,
                pb: 1.5,
                pl: 0,
                // pt: 2,
                bottom: fixedPagination ? 0 : 'auto',
                bottom: 0,
                background: 'rgba(255,255,255,0.8)',
                backdropFilter: 'blur(4px)',
                // background:
                //   'linear-gradient(0deg, rgba(0,255,255,1) 0%, rgba(255,255,255,0) 100%)',
              }}
              {...pagination}
            >
              {setSearch && <AutoSearchResetButton />}
            </Pagination>
          )}
        </TableContainer>
      </AutoTableSearchForm>
    </>
  )
}

export const Body = ({ children, ...rest }) => {
  return (
    <Table {...rest}>
      <AnimatePresence mode="sync">
        {/* {React.Children.map(children, child =>
          React.cloneElement(child, props.motion)
        )} */}
        {children}
      </AnimatePresence>
    </Table>
  )
}

export default AutoTable
